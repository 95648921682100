import { Link } from "gatsby";
import React, { Component } from "react";
import { Container, Menu, Icon, Button, Modal } from "semantic-ui-react";
import colors from "../colors";
import logo from "../../images/rocksteady-logo.png";
import headerStyles from "./header.module.css";
import { FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL } from "../../constants";

const LogoHomeLink = () => (
  <Link to="/" className={headerStyles.logoLink}>
    <img
      src={logo}
      className={headerStyles.logoImage}
      alt="Rocksteady Music School"
    />
  </Link>
);

const MenuLink = ({ to, text, active, colorClass }) => (
  <Menu.Item
    fitted="horizontally"
    active={active}
    as={Link}
    to={to}
    className={`${headerStyles.mainMenuLink} ${
      active ? headerStyles.mainMenuLinkActive : ""
    }`}
    style={{
      borderColor: active ? colors[colorClass].main : "",
    }}
  >
    {text}
  </Menu.Item>
);

const pathStartsWith = (path, match) => {
  if (path.indexOf(match) === 0) {
    return true;
  }
  return false;
};

const MainNavLinks = (props) => (
  <>
    <MenuLink
      to="/info-for-parents/"
      text="For Parents"
      active={pathStartsWith(props.path, "/info-for-parents")}
      colorClass="primary"
    />
    <MenuLink
      to="/info-for-schools/"
      text="For Schools"
      active={pathStartsWith(props.path, "/info-for-schools")}
      colorClass="primary"
    />
    <MenuLink
      to="/careers/"
      text="Careers"
      active={pathStartsWith(props.path, "/careers")}
      colorClass="primary"
    />
  </>
);

const MicroSiteLinks = (props) => {
  return (
    <>
      {resourcesButton(headerStyles.micrositeMenuLink)}
      {contactsButton(props.path, headerStyles.micrositeMenuLink)}
    </>
  );
};

const SupportLinks = ({ path }) => {
  const onSchoolsPage = pathStartsWith(path, "/info-for-schools")

  return (
    <>
      <Menu.Item fitted="horizontally" className={headerStyles.loginItem}>
        <Button
          primary
          href={
            onSchoolsPage
              ? 'https://portal.rocksteadymusicschool.com'
              : 'https://backstage.rocksteadymusicschool.com/sign-in'
          }
          className={headerStyles.loginButton} 
          compact
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="user outline" />
          {onSchoolsPage ? 'School Login' : 'Parent Login'}
        </Button>
      </Menu.Item>
      <Menu.Item fitted="horizontally">
        <Button
          as={Link}
          to="/contact/"
          basic
          className={headerStyles.contactButton}
          compact
        >
          <span>Contact</span>
        </Button>
      </Menu.Item>
    </>
  )
};

const DesktopMenu = (props) => {
  if (props.menuEnabled) {
    return (
      <Container className={headerStyles.container}>
        <Menu borderless className={headerStyles.menuStyles}>
          <LogoHomeLink />

          <Menu.Menu position="right" className={headerStyles.itemsRight}>
            {props.microsite ? (
              <MicroSiteLinks path={props.path} />
            ) : (
              <>
                <MainNavLinks path={props.path} />
                <SupportLinks path={props.path} />
              </>
            )}
          </Menu.Menu>
        </Menu>
      </Container>
    );
  }

  return (
    <Container className={headerStyles.container}>
      <Menu borderless className={headerStyles.menuStyles}>
        <LogoHomeLink />

        <Menu.Menu
          position="right"
          className={headerStyles.itemsRight}
        ></Menu.Menu>
      </Menu>
    </Container>
  );
};

const MobileHeader = (props) => {
  if (props.menuEnabled) {
    return (
      <div className={headerStyles.mobilePanel}>
        <LogoHomeLink />
        <div className={headerStyles.mobileMenuButtonWrapper}>
          <ModalExampleControlled microsite={props.microsite} path={props.path} />
        </div>
      </div>
    );
  }

  return (
    <div className={headerStyles.mobilePanel}>
      <LogoHomeLink />
    </div>
  );
};

const ModalContent = ({ handleClose }) => (
  <>
    <div className={headerStyles.utilButtons}>
      <Button
        icon
        circular
        size="large"
        className={headerStyles.mobileMenuClose}
        onClick={handleClose}
      >
        <Icon name="close" />
      </Button>
    </div>
    <div className={headerStyles.modalContentWrapper}>
      <Link to="/">Home</Link>
      <ul className={headerStyles.modalPrimaryLinks}>
        {
          [
            ['/info-for-parents/', 'FOR PARENTS', colors.primary.main],
            ['/info-for-schools/', 'FOR SCHOOLS', colors.primary.main],
            ['/foundation/', 'OUR FOUNDATION', colors.foundation.main]
          ].map(([href, text, color], i) => (
            <li key={`big-internal-link-${i + 1}`}>
              <Link to={href} style={{ color }} activeStyle={{ textDecoration: 'underline' }}>
                {text}
              </Link>
            </li>
          ))}
      </ul>
      <ul className={headerStyles.modalSecondaryLinks}>
        {
          [
            ['https://backstage.rocksteadymusicschool.com/sign-in', 'PARENT LOGIN'],
            ['https://portal.rocksteadymusicschool.com', 'SCHOOL LOGIN']
          ].map(([href, text], i) => (
            <li key={`external-link-${i + 1}`}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            </li>
          ))
        }
        {
          [['/contact/', 'CONTACT'], ['/careers/', 'CAREERS']].map(([href, text], i) => (
            <li key={`small-internal-link-${i + 1}`}>
              <Link to={href} activeStyle={{ textDecoration: 'underline' }}>
                {text}
              </Link>
            </li>
          ))
        }
      </ul>

      {/* SOCIAL */}
      <p className={headerStyles.modalSocialLinks}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={FACEBOOK_URL}
        >
          <Icon name="facebook" size="huge" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={TWITTER_URL}
        >
          <Icon name="twitter" size="huge" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={INSTAGRAM_URL}
        >
          <Icon name="instagram" size="huge" />
        </a>
      </p>

      {/* POLICIES */}
      <ul className={headerStyles.modalPolicyLinks}>
        <li>
          <a href="/privacy-policy/">Privacy Notice</a>
        </li>
        <li>
          <Link to="/cookie-policy/">Cookie Policy</Link>
        </li>
        <li>
          <Link to="/terms-conditions/">Website Terms &amp; Conditions</Link>
        </li>
      </ul>

      <p>&copy; {new Date().getFullYear()} Rocksteady Music School</p>
    </div>
  </>
);

const MicrositeModalContent = ({ handleClose, path }) => (
  <>
    <div className={headerStyles.utilButtons}>
      <Button
        icon
        circular
        size="large"
        className={headerStyles.mobileMenuClose}
        onClick={handleClose}
      >
        <Icon name="close" />
      </Button>
    </div>
    <div className={headerStyles.modalContentWrapper}>
      <ul className={headerStyles.modalSecondaryLinks}>
        <li>{resourcesButton("")}</li>  
        <li>{contactsButton(path, "")}</li>
      </ul>
    </div>
  </>
);

const resourcesButton = (className) => (
  <a
    href="https://portal.rocksteadymusicschool.com/feel-good-friday"
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    MORE FREE RESOURCES
  </a>
)

const contactsButton = (path, className) => {
  if (path.includes("/contacts")) {
    return null;
  }

  return (
    <a
      href="/contacts"
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      CONTACT
    </a>
  )
}

class ModalExampleControlled extends Component {
  state = { modalOpen: false };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
      <>
        <Button
          compact
          basic
          className={headerStyles.menuButton}
          onClick={this.handleOpen}
        >
          {this.props.microsite ? "" : "MENU"}
        </Button>
        <Button
          icon
          circular
          size="large"
          className={headerStyles.mobileMenuButton}
          onClick={this.handleOpen}
        >
          <Icon name="bars" color="blue" />
        </Button>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="fullscreen"
          id={headerStyles.modal}
        >
          <Modal.Content scrolling>
            {this.props.microsite ? (
              <MicrositeModalContent handleClose={this.handleClose} path={this.props.path} />
            ) : (
              <ModalContent handleClose={this.handleClose} />
            )}
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const SiteHeader = (props) => (
  <div>
    <div className={headerStyles.desktopHeader}>
      <DesktopMenu
        path={props.path}
        menuEnabled={props.menuEnabled}
        microsite={props.microsite}
      />
    </div>
    <div className={headerStyles.mobileHeader}>
      <MobileHeader
        path={props.path}
        menuEnabled={props.menuEnabled}
        microsite={props.microsite}
      />
    </div>
  </div>
);

export default SiteHeader;
