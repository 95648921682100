import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Container } from "semantic-ui-react";
import { Location } from "@reach/router";
import { ParallaxProvider } from "react-scroll-parallax";

import "semantic-ui-css/semantic.min.css";
import "./layout.css";

import SiteHeader from "../site-header/site-header";
import SiteFooter from "../site-footer/site-footer";
import CookieConsentModal from '../cookie-consent-modal';

const Layout = ({ children, menuEnabled, microsite }) => {
  const pageTitle = children[0].props.title.toLowerCase();

  useEffect(() => {
    // disable zendesk cookies
    window.zESettings = {
      cookies: false
    };
  }, []);

  const excludeFromCareers = (component) => {
    if (pageTitle.includes("careers")) {
      return null
    } else {
      return component;
    }
  }

 

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ParallaxProvider>
          <Container className="main-container">
            <Helmet>
              <script
                  id="ze-snippet"
                  src="https://static.zdassets.com/ekr/snippet.js?key=1fc5c407-8a75-437b-9784-eb704fed5ab3"
              />
            </Helmet>
            <Location>
              {({ location }) => <SiteHeader path={location.pathname} menuEnabled={menuEnabled} microsite={microsite} />}
            </Location>
            {children}
            <CookieConsentModal />
            {excludeFromCareers(<SiteFooter microsite={microsite} />)}
          </Container>
        </ParallaxProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menuEnabled: PropTypes.bool
};

Layout.defaultProps = {
  menuEnabled: true
}

export default Layout;
